.not-found-page {
  background: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  color: white;
  flex-wrap: wrap;

  .content-not-found {
    margin: 16px;
    flex-wrap: wrap;
    animation: fadeIn 1.5s 1.5s backwards;
  
    h1 {
      padding: 16px;
      text-align: center;
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 700;
    
      // Small devices, somente mostrando os textos
      @media screen and (max-width: 730px) {
        font-size: 40px;
        line-height: 40px;
      }
  
      @media screen and (max-width: 520px) {
        font-size: 32px;
        line-height: 32px;
      }
    }

    .text-info {
      span {
        width: 100%;
        display: inline-block;
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 16px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 100;
        margin-block-start: 0.2em;
        margin-block-end: 0.2em;
        &:hover {
          color: #a3a3a3;
        }
      }
      .btn-group {
        animation: fadeIn backwards;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
      }
      .btn-section {
        width: 50%;
        cursor: pointer;
        color: #f2f2f2;
        background: #262626;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 12px 0px;
        border: 1px solid #f2f2f2;
        text-align: center;
        white-space: nowrap;
        max-width: 424px;
        &:hover {
          background: #333333;
          font-weight: 600;
        }
        @media screen and (max-width: 560px) {
          width: 70%;
        }
      }
    }
  }
}

