header {
  width: 100%;
  z-index: 999;
  position: absolute;
  background-color: #333333;
  // border-radius: 0px 0px 16px 16px;
  .container-header {
    padding: 16px;
    overflow: hidden;
    @media screen and (max-width: 599px) {
      border-radius: 0px 0px 16px 16px;
      padding: 0px;
      overflow: hidden;
      position: absolute;
      width: 100%;
      background-color: #333333;
    }
    .big-size {
      display: none;
    }

    .small-size {
      display: none;
    }

    .other-links {
      display: none;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
    }

    @media screen and (min-width: 600px) {
      .big-size {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    @media screen and (max-width: 599px) {
      .small-size {
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .showing {
        display: flex;
        
        background-color: rgb(51 51 51 / 75%);
        -webkit-backdrop-filter: 4px;
        backdrop-filter: blur(4px);

        margin: -16px 16px 8px 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        a {
          margin-top: 4px;
        }
      }
    }
  }

  a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    text-decoration: none;
    border: none;
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    svg {
      font-size: 20px;
    }
    @media screen and (min-width: 600px) {
      padding: 16px 16px;
    }

    @media screen and (max-width: 599px) {
      svg {
        padding: 16px 16px;
      }
    }
  }

  .icon-effect {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a:hover {
    background-color: #4d4d4d;
    svg,.description-link {
      color: #f2f2f2;
    }
    @media screen and (max-width: 599px) {
      background-color: #333333;
      .icon-effect {
        border-radius: 50%;
        background-color: #4d4d4d;
      }
    }
    
  }

  a.active {
    background-color: #4d4d4d;
    svg,.description-link {
      color: #f2f2f2;
    }
    @media screen and (max-width: 599px) {
      background-color: #333333;
      .icon-effect {
        border-radius: 50%;
        background-color: #4d4d4d;
      }
    }
    .description-link {
      color: #f2f2f2;
    }
  }

  .description-link {
    padding-left: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #b5b5b5;
  }

  .nav-links-big {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .menu-and-informations, .btn-menu {
    p {
      color: #f2f2f2;
      font-weight: 400;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    small {
      color: #a3a3a3;
      font-weight: 400;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .infos-text {
    font-size: 16px;
    text-align: right;
  }

  .btn-menu {
    visibility: hidden;
    cursor: pointer;
    background: #333333;
    border: none;
    
    svg {
      position: absolute;
      font-size: 28px;
    }

    .showing-icon {
      visibility: visible;
    }
  }

}