.contact-page {
  background: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 88px; // header
  margin-bottom: 68px; // footer
  min-height: calc(100vh - (88px + 64px));
  color: white;
  flex-wrap: wrap;

  .contact {
    margin: 16px;
    flex-wrap: wrap;
    animation: fadeIn 1.5s 1.5s backwards;
  
    h1 {
      padding: 16px;
      text-align: center;
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
    
      // Small devices, somente mostrando os textos
      @media screen and (max-width: 730px) {
        font-size: 40px;
        line-height: 40px;
      }
  
      @media screen and (max-width: 520px) {
        font-size: 32px;
        line-height: 32px;
      }
    }
    
    .contact-mail {
      padding-bottom: 16px;
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 920px;
      
        label {
          padding-bottom: 4px;
          color: #c9c9c9;
          font-size: 16px;
          font-weight: 400;
        }
      
        input, textarea {
          color: #333333;
          background: #d4d4d4;
          font-size: 14px;
          font-weight: 400;
          border: none;
          border-radius: 4px;
          padding: 16px;
        }
      
        textarea {
          resize: none;
        }
      
        input:focus,textarea:focus { 
          outline: none !important;
          border-color: #d4d4d4;
          box-shadow: 0 0 4px #d4d4d4;
        }
      
        span {
          display: flex;
          align-items: center;
          color: #c87a83;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          svg {
            font-size: 18px;
            margin-right: 8px;
          }
        }

        .first-infos {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
      
          .name, .email {
            display: flex;
            justify-content: flex-start;
            flex-grow: 1;
            padding-bottom: 8px;
            flex-direction: column;
          }
          .name {
            padding-right: 8px;
          }
          .email {
            padding-left: 8px;
          }
          @media screen and (max-width: 599px) {
            flex-direction: column;
            .name {
              padding-right: 0px;
            }
            .email {
              padding-left: 0px;
            }
          }
        }
        .message-section {
          padding-bottom:16px;
          display: flex;
          flex-direction: column;
        }
        .btn-group {
          animation: fadeIn backwards;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            animation: fadeIn backwards;
            width: 44.5px;
          }

          
        }
        .sent-message {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          span {
            color: #a3a3a3;
            font-size: 24px;
          }
          svg {
            font-size: 24px;
          }
        }
        .btn-section {
          width: 50%;
          cursor: pointer;
          color: #f2f2f2;
          background: #262626;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 4px;
          font-family: sans-serif;
          text-decoration: none;
          padding: 12px 0px;
          border: 1px solid #f2f2f2;
          text-align: center;
          white-space: nowrap;
          &:hover {
            background: #333333;
            font-weight: 600;
          }
        }
        /* Utilizando para mudar o placeholder */
        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: #333333;
          opacity:  0.8;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #333333;
          opacity:  0.8;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: #333333;
          opacity:  0.8;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #333333;
          opacity:  0.8;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: #333333;
          opacity:  0.8;
        }
        ::placeholder { /* Most modern browsers support this now. */
          color: #333333;
          opacity:  0.8;
        }
      }
    }

    .map-section {
      margin: 0 auto;
      max-width: 920px;
      padding-bottom: 16px;
    }
    
    .leaflet-container {
      border-radius: 8px;
      display: flex;
      flex-grow: 1;
      position: relative;
      height: 350px;
    }
  }
}

.submit-form {
  .loader-active {
    display: block;
    position: relative;

    width: 56px;
    height: 56px;
    animation: fadeOut 1s 1s;
    animation-fill-mode: forwards;
  }
}