.footer {
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #a3a3a3;
  min-height: 64px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* blurry glass effect */
  background-color: rgb(51 51 51 / 85%);
  -webkit-backdrop-filter: 4px;
  backdrop-filter: blur(4px);
  font-weight: 500;
  p {
    font-size: 14px;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
  }

  .social-medias {
    display: flex;
    flex-direction: row;
    
    a {
      color: #a3a3a3;
      cursor: pointer;
      font-size: 14px;
      padding: 0px 16px;
      margin-right: 0px 24px;
      text-decoration: none;
    }
    a:hover {
      color: #878787;
    }
  }

}