.container-app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.container-page-app {
  width: 100%;
  min-height: 100vh;
}